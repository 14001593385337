
// import {bootUserPermissions} from '@/app/booting/boot'


export const authGaurd =  (to, from, guards, guardProps, props) => {
  if ($store.getters['App-User-state/isAuthenticated']) {
    return true
  } else {
    props.redirect = { name: 'portal-home-login'}
    $store.state['App-User-state'].setAfterLoginLocation = to.path
    $services['notify']({
      type: 'warning',
      title: $vue.prototype.$ml.get('page_access_denied'),
      message: '',
      position: 'center',
      close: false,
      timeout: 1500,
      animateInside: false,
      onClosed: () => {}
    });
    return false
  }
}

export default authGaurd
